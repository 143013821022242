import React from "react";
import { Puff } from "react-loader-spinner";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const PageLoader = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Puff
          height="80"
          width="80"
          radius="9"
          color="#ef4568"
          ariaLabel="three-dots-loading"
          wrapperStyle
          wrapperClass=""
        />
        <Typography variant="body1" sx={{fontWeight:'unset' , mt:2 , textTransform:'capitalize'}}> MFAH Magazine is Loading........</Typography>
      </Box>
    </div>
  );
};

export default PageLoader;
