import './App.css';
import Naviagation from "./Routes/Naviagation"



function App() {


  return (
    <div>

      <Naviagation/>

    </div>
  );
}

export default App;
